import classNames from 'classnames';
import { ReactNode } from 'react';

export type TagColor = 'green' | 'teal' | 'blue' | 'yellow' | 'red' | 'white' | 'gray' | 'indigo';

export interface TagProps {
  text: string;
  icon?: ReactNode;
  color?: TagColor;
  outline?: boolean;
}

export default function Tag(props: TagProps): JSX.Element {
  return (
    <div
      className={classNames(
        'inline-flex items-center space-x-1 text-xs font-bold border rounded-md py-0.5 pl-1 pr-1.5 select-none whitespace-nowrap',
        {
          'bg-green-300 border-green-300': props.color === 'green' && !props.outline,
          'bg-teal-300 border-teal-300': props.color === 'teal' && !props.outline,
          'bg-indigo-300 border-indigo-300': props.color === 'indigo' && !props.outline,
          'bg-blue-300 border-blue-300': props.color === 'blue' && !props.outline,
          'bg-yellow-300 border-yellow-300': props.color === 'yellow' && !props.outline,
          'bg-white border-black': props.color === 'white' && !props.outline,
          'bg-red-300 border-red-300': props.color === 'red' && !props.outline,
          'bg-gray-300 border-gray-300': props.color === 'gray' && !props.outline,
          'border-green-300': props.color === 'green' && props.outline,
          'border-teal-300': props.color === 'teal' && props.outline,
          'border-blue-300': props.color === 'blue' && props.outline,
          'border-yellow-300': props.color === 'yellow' && props.outline,
          'border-black': props.color === 'white' && props.outline,
          'border-red-300': props.color === 'red' && props.outline,
          'border-gray-300': props.color === 'gray' && props.outline,
          'bg-black !text-white border-black': !props.color,
          '!text-black': props.color,
        },
      )}
    >
      {props.icon && <span className="inline-block size-3">{props.icon}</span>}
      <span>{props.text}</span>
    </div>
  );
}
